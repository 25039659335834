// Generated by CoffeeScript 1.7.1
(function() {
  var Enum;

  Enum = (function() {
    function Enum(type, options) {
      this.type = type;
      this.options = options != null ? options : [];
    }

    Enum.prototype.decode = function(stream) {
      var index;
      index = this.type.decode(stream);
      return this.options[index] || index;
    };

    Enum.prototype.size = function() {
      return this.type.size();
    };

    Enum.prototype.encode = function(stream, val) {
      var index;
      index = this.options.indexOf(val);
      if (index === -1) {
        throw new Error("Unknown option in enum: " + val);
      }
      return this.type.encode(stream, index);
    };

    return Enum;

  })();

  module.exports = Enum;

}).call(this);
