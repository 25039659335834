// Generated by CoffeeScript 1.7.1
(function() {
  var BufferT, NumberT, utils;

  utils = require('./utils');

  NumberT = require('./Number').Number;

  BufferT = (function() {
    function BufferT(length) {
      this.length = length;
    }

    BufferT.prototype.decode = function(stream, parent) {
      var length;
      length = utils.resolveLength(this.length, stream, parent);
      return stream.readBuffer(length);
    };

    BufferT.prototype.size = function(val, parent) {
      if (!val) {
        return utils.resolveLength(this.length, null, parent);
      }
      return val.length;
    };

    BufferT.prototype.encode = function(stream, buf, parent) {
      if (this.length instanceof NumberT) {
        this.length.encode(stream, buf.length);
      }
      return stream.writeBuffer(buf);
    };

    return BufferT;

  })();

  module.exports = BufferT;

}).call(this);
