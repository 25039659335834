// Generated by CoffeeScript 1.7.1
(function() {
  var DecodeStream, Fixed, NumberT,
    __hasProp = {}.hasOwnProperty,
    __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

  DecodeStream = require('./DecodeStream');

  NumberT = (function() {
    function NumberT(type, endian) {
      this.type = type;
      this.endian = endian != null ? endian : 'BE';
      this.fn = this.type;
      if (this.type[this.type.length - 1] !== '8') {
        this.fn += this.endian;
      }
    }

    NumberT.prototype.size = function() {
      return DecodeStream.TYPES[this.type];
    };

    NumberT.prototype.decode = function(stream) {
      return stream['read' + this.fn]();
    };

    NumberT.prototype.encode = function(stream, val) {
      return stream['write' + this.fn](val);
    };

    return NumberT;

  })();

  exports.Number = NumberT;

  exports.uint8 = new NumberT('UInt8');

  exports.uint16be = exports.uint16 = new NumberT('UInt16', 'BE');

  exports.uint16le = new NumberT('UInt16', 'LE');

  exports.uint24be = exports.uint24 = new NumberT('UInt24', 'BE');

  exports.uint24le = new NumberT('UInt24', 'LE');

  exports.uint32be = exports.uint32 = new NumberT('UInt32', 'BE');

  exports.uint32le = new NumberT('UInt32', 'LE');

  exports.int8 = new NumberT('Int8');

  exports.int16be = exports.int16 = new NumberT('Int16', 'BE');

  exports.int16le = new NumberT('Int16', 'LE');

  exports.int24be = exports.int24 = new NumberT('Int24', 'BE');

  exports.int24le = new NumberT('Int24', 'LE');

  exports.int32be = exports.int32 = new NumberT('Int32', 'BE');

  exports.int32le = new NumberT('Int32', 'LE');

  exports.floatbe = exports.float = new NumberT('Float', 'BE');

  exports.floatle = new NumberT('Float', 'LE');

  exports.doublebe = exports.double = new NumberT('Double', 'BE');

  exports.doublele = new NumberT('Double', 'LE');

  Fixed = (function(_super) {
    __extends(Fixed, _super);

    function Fixed(size, endian, fracBits) {
      if (fracBits == null) {
        fracBits = size >> 1;
      }
      Fixed.__super__.constructor.call(this, "Int" + size, endian);
      this._point = 1 << fracBits;
    }

    Fixed.prototype.decode = function(stream) {
      return Fixed.__super__.decode.call(this, stream) / this._point;
    };

    Fixed.prototype.encode = function(stream, val) {
      return Fixed.__super__.encode.call(this, stream, val * this._point | 0);
    };

    return Fixed;

  })(NumberT);

  exports.Fixed = Fixed;

  exports.fixed16be = exports.fixed16 = new Fixed(16, 'BE');

  exports.fixed16le = new Fixed(16, 'LE');

  exports.fixed32be = exports.fixed32 = new Fixed(32, 'BE');

  exports.fixed32le = new Fixed(32, 'LE');

}).call(this);
