exports.G = 'G';
exports.Svg = 'SVG';
exports.View = 'VIEW';
exports.Text = 'TEXT';
exports.Link = 'LINK';
exports.Page = 'PAGE';
exports.Note = 'NOTE';
exports.Path = 'PATH';
exports.Rect = 'RECT';
exports.Line = 'LINE';
exports.Stop = 'STOP';
exports.Defs = 'DEFS';
exports.Image = 'IMAGE';
exports.Tspan = 'TSPAN';
exports.Canvas = 'CANVAS';
exports.Circle = 'CIRCLE';
exports.Ellipse = 'ELLIPSE';
exports.Polygon = 'POLYGON';
exports.Document = 'DOCUMENT';
exports.Polyline = 'POLYLINE';
exports.ClipPath = 'CLIP_PATH';
exports.TextInstance = 'TEXT_INSTANCE';
exports.LinearGradient = 'LINEAR_GRADIENT';
exports.RadialGradient = 'RADIAL_GRADIENT';
