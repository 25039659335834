"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

exports.__esModule = true;
exports.default = void 0;

var _advanceWidth = _interopRequireDefault(require("./advanceWidth"));

var _advanceWidthBetween = _interopRequireDefault(require("./advanceWidthBetween"));

var _append = _interopRequireDefault(require("./append"));

var _ascent = _interopRequireDefault(require("./ascent"));

var _copy = _interopRequireDefault(require("./copy"));

var _descent = _interopRequireDefault(require("./descent"));

var _dropLast = _interopRequireDefault(require("./dropLast"));

var _empty = _interopRequireDefault(require("./empty"));

var _end = _interopRequireDefault(require("./end"));

var _fromFragments = _interopRequireDefault(require("./fromFragments"));

var _glyphWidthAt = _interopRequireDefault(require("./glyphWidthAt"));

var _height = _interopRequireDefault(require("./height"));

var _indexAtOffset = _interopRequireDefault(require("./indexAtOffset"));

var _insertGlyph = _interopRequireDefault(require("./insertGlyph"));

var _leadingOffset = _interopRequireDefault(require("./leadingOffset"));

var _length = _interopRequireDefault(require("./length"));

var _prepend = _interopRequireDefault(require("./prepend"));

var _reduce = _interopRequireDefault(require("./reduce"));

var _runAt = _interopRequireDefault(require("./runAt"));

var _runIndexAt = _interopRequireDefault(require("./runIndexAt"));

var _slice = _interopRequireDefault(require("./slice"));

var _sliceAtOffset = _interopRequireDefault(require("./sliceAtOffset"));

var _start = _interopRequireDefault(require("./start"));

var _trailingOffset = _interopRequireDefault(require("./trailingOffset"));

var _trim = _interopRequireDefault(require("./trim"));

var _default = {
  advanceWidth: _advanceWidth.default,
  advanceWidthBetween: _advanceWidthBetween.default,
  append: _append.default,
  ascent: _ascent.default,
  copy: _copy.default,
  descent: _descent.default,
  dropLast: _dropLast.default,
  empty: _empty.default,
  end: _end.default,
  fromFragments: _fromFragments.default,
  glyphWidthAt: _glyphWidthAt.default,
  height: _height.default,
  indexAtOffset: _indexAtOffset.default,
  insertGlyph: _insertGlyph.default,
  leadingOffset: _leadingOffset.default,
  length: _length.default,
  prepend: _prepend.default,
  reduce: _reduce.default,
  runAt: _runAt.default,
  runIndexAt: _runIndexAt.default,
  slice: _slice.default,
  sliceAtOffset: _sliceAtOffset.default,
  start: _start.default,
  trailingOffset: _trailingOffset.default,
  trim: _trim.default
};
exports.default = _default;