// Generated by CoffeeScript 1.7.1
(function() {
  var Reserved, utils;

  utils = require('./utils');

  Reserved = (function() {
    function Reserved(type, count) {
      this.type = type;
      this.count = count != null ? count : 1;
    }

    Reserved.prototype.decode = function(stream, parent) {
      stream.pos += this.size(null, parent);
      return void 0;
    };

    Reserved.prototype.size = function(data, parent) {
      var count;
      count = utils.resolveLength(this.count, null, parent);
      return this.type.size() * count;
    };

    Reserved.prototype.encode = function(stream, val, parent) {
      return stream.fill(0, this.size(val, parent));
    };

    return Reserved;

  })();

  module.exports = Reserved;

}).call(this);
